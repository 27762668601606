import React ,{useState, useEffect} from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import {Link, navigate} from 'gatsby';
import styled from 'styled-components';
import config from '../../aws-exports';
import {getEsDownDocs, createOnRedis} from '../../graphql/queries'
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { useSelector, useDispatch } from "react-redux";
import Loader from '../../assets/loading.gif';
import  secureLocalStorage  from  "react-secure-storage";
import ForgotPassword from '../Modals/ForgotPassword';
Amplify.configure(config);

const Login = (props) => {
    const {consoleLog} = require('../commonFunctions.js');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [generaicError, setgeneraicError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showForgotPasswordModal, setShowForgotPasswordModal] =  useState(false);

    const proxyToken = props.url_params?.token;
    const proxyDocTypeId = props.url_params?.doc_type_id;
    const proxyOrderId = props.url_params?.order_id;
    consoleLog('proxyToken login.jsx props', props);
    if(proxyToken){
        consoleLog('proxyToken is set');
    }

    const dispatch = useDispatch();
    async function getTokenUser (){
        setLoading(true);


        consoleLog('proxyToken getTokenUser');

        let json = {};
        if(proxyToken){
            json = {
                proxyToken: proxyToken,
                type: 'prodocs'
            }; 
        }else{
            json = {
                userName: email.trim(),
                accessPass: password.trim(),
                type: 'prodocs'
            };    
        }

        let loginUrl = process.env.GATSBY_API_BASE_URL;
    
        const success = await fetch(`${loginUrl}account/getToken`, {
            method: 'POST',
            body: new URLSearchParams({json: JSON.stringify(json)}),
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then(res => res.json())
        .then(json => { 
            return json;
        });
        if(success.status == 1){
            let clientData = {...success.clientData}
            consoleLog('getTokenUser clientData>',clientData);
            secureLocalStorage.setItem('client_data', JSON.stringify(clientData));	
            secureLocalStorage.setItem('user_token', success.token);
            const esd = await API.graphql({
                query: getEsDownDocs,
                variables: {
                    team_id: clientData.team_id
                }
            });
            consoleLog('getTokenUser es_down_docs>',esd.data.getEsDownDocs);
            secureLocalStorage.setItem('es_down_docs', esd.data.getEsDownDocs);
            
            const create_on_redis = await API.graphql({
                query: createOnRedis,
                variables: {
                  id: clientData.client_id,
                  index: 'prodocs_user_auth_data',
                  data: JSON.stringify(clientData)
                }
            });
            consoleLog('getTokenUser create_on_redis:', create_on_redis);

            // secureLocalStorage.setItem('user_templates', JSON.stringify(success.templates));
            // dispatch({ type: "USER_TEMPLATES", value: success.templates });
            if (proxyDocTypeId) {
                navigate(`/createdoc?doc_type_id=${proxyDocTypeId}&order_id=${proxyOrderId}`);
            }else{
                navigate('/document');
            }
        }
        else{
            setLoading(false);
            setgeneraicError('Invalid Credentials')
        }
    }
    const handleEmailChange = (e) => {
        setEmailError('');
        setEmail(e.target.value)
    }
    const handlePasswordChange = (e) => {
        setPasswordError('');
        setPassword(e.target.value)
    }
    // Function to check if conditions are met (6 characters in email and 1 in password)
    const isButtonActive = email.length >= 6 && password.length >= 1;

    const submitValue = () => {
        if(email == ''){
            setEmailError('Email Required')
        }
        if(password == ''){
            setPasswordError('Password Required')
        }
        if(email !== '' && password !== ''){
            getTokenUser();
        }
    }

    const submitKey = (e) => {
        if(e.key == 'Enter') {
            submitValue()
        }
    }

    async function confirmEmail(e) {
        console.log("confirmEmail", e)
    }

    useEffect(()=>{
        if(proxyToken){
            consoleLog('proxyToken useEffect proxyToken', proxyToken);
            getTokenUser();
        }
    }, []);

    return (
        <>
            { loading ?
                <LoadingContainer>
                    <img className="loader" src={Loader}/>
                </LoadingContainer>
            :
                <FormLogin>
                    <MDBContainer className="w-100">

                        <MDBRow className="w-100 align-items-center justify-content-center m-0">
                            <MDBCol md="4">

                                <form className="w-100">
                                    <h2 className="login-text text-center">LOG IN</h2>
                                    <div class="mb-3 pt-2">
                                        <input type="email" id="defaultFormLoginEmailEx" className="form-control login-input" placeholder="Email Address" onChange={handleEmailChange} onKeyPress={submitKey} />
                                    </div>
                                    
                                    {emailError &&
                                        <div style={{color:'red'}}>{emailError}</div>
                                    }

                                    <div class="mb-3 pt-2">
                                        <input type="password" id="defaultFormLoginPasswordEx" className="form-control login-input" placeholder="Password" onChange={handlePasswordChange} onKeyPress={submitKey} />
                                    </div>

                                    <a href="javascript:void(0)" class="forget-link" onClick={() => setShowForgotPasswordModal(true)}>Forgot password</a>
                                    
                                    {passwordError && <div style={{color:'red', float:'right'}}>{passwordError}</div> }

                                    {generaicError && <div style={{color:'red', float:'right'}} >{generaicError}</div> }

                                    <div className="text-center mb-3 pt-4">
                                        <Btn onClick={submitValue} active={isButtonActive} disabled={!isButtonActive}>
                                            <div className="w-100" style={{ cursor: "pointer" }}>
                                                Login
                                            </div>
                                        </Btn>
                                    </div>

                                    <div class="create-account-link text-center pt-3">
                                        <a href="https://roofscope.com/customer/register" target="_blank" class="create-account-link text-center">Create Account</a>
                                    </div>
                                </form>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </FormLogin>
            }
            {
                showForgotPasswordModal &&
                <ForgotPassword
                    showModal={showForgotPasswordModal}
                    setShowModal={setShowForgotPasswordModal}
                    confirm={confirmEmail}
                />
            }
        </>
    )
};

export default Login;

const LoadingContainer = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    .loader{
        -webkit-user-select: none;
        margin: auto;
        // background-color: rgba(255,255,255, 0.5);
        // transition: background-color 300ms;
    }
`

const FormLogin = styled.div`
    .login-text {
        font-family: Rajdhani;
        font-weight: bold !important;
        font-size: 25px;
        letter-spacing: 0px;
        color: #212E33;
        text-transform: uppercase;
        opacity: 1;
    }
    .login-input {
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        border: 1px solid #B4BEBF !important;
        border-radius: 3px !important;
        opacity: 1;
    }
    input[type="text"], input[type="email"], input[type="password"] {
        height: 48px !important;
        font-size: 14px !important;
        letter-spacing: 0px;
        line-height: 24px;
        color: #414243 !important;
        font-family: Roboto;
        outline: none;
        font-style: normal;
    }
    .forget-link {
        top: 419px;
        left: 784px;
        width: 349px;
        height: 24px;
        font-family: Roboto;
        text-align: left;
        text-decoration: underline;
        letter-spacing: 0px;
        color: #212e3380;
        opacity: 1;
        font-size: 16px;
        line-height: 24px;
    }
    .create-account-link {
        text-decoration: underline;
        letter-spacing: 0px;
        color: #14848E;
        opacity: 1;
        font-size: 14px;
        line-height: 18px;
        font-family: 'Roboto';
    }

    width:100%;
    height:85vh;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column
`
const Btn = styled.div`

    height:48px;
    text-transform:uppercase;
    display:flex;
    align-items:center;
    justify-content:center;
    background: ${(props) => (props.active ? "#DE841E" : "#D6E1E5")};
    color: ${(props) => (props.active ? "#ffffff" : "#737F82")};

    font: normal normal 600 16px / 21px Rajdhani;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    width: 100%;
    border-radius: 3px;
    opacity: 1;
    
    &:hover{
        background-color:#DE841E !important;
        color:#ffffff !important;
    }
`